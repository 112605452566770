@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-text-field {
  // Form headings
  &.h2 {
    .v-input__slot {
      .v-text-field__slot {
        font-size: var(--font-size-h2);
      }
    }
  }
  &.h3 {
    .v-input__slot {
      .v-text-field__slot {
        font-size: var(--font-size-h3);
      }
    }
  }
  &.equal-before,
  &.equal-after {
    position: relative;
  }
  &.equal-before::before,
  &.equal-after::after {
    position: absolute;
    top: 0.5rem;
    content: "\003d"; // equal character
    font-size: var(--font-size-h2);
  }
  &.equal-before::before {
    left: -1.05rem;
    @media ($wx-lg-min) {
      left: -1.1rem;
    }
    @media ($wx-xl-min) {
      left: -1.2rem;
    }
  }
  &.equal-after::after {
    right: -1.05rem;
    @media ($wx-lg-min) {
      right: -1.1rem;
    }
    @media ($wx-xl-min) {
      right: -1.2rem;
    }
  }

  /**
  * Filter style = No border-bottom
  * prop `hide-details` OR `class="filter-style"`
  */
  &.filter-style,
  &.v-input--hide-details {
    .v-input__control {
      .v-input__slot {
        border-radius: var(--border-radius-form-elements);

        // hide border-bottom
        &::before,
        &::after {
          display: none;
          border-color: transparent !important;
        }
      }
    }
  }

  // .v-text-field__suffix
  &__suffix {
    margin-top: 15px;
  }
}
